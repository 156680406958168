<template>
  <v-container cols="12" class="pl-1 pr-1 mt-5 mb-5">
    <v-row>
      <v-col cols="6" sm="4" md="3" class="pa-0 ma-0">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{ $t('customers.nip') }}</v-list-item-title>
            <v-list-item-subtitle>{{ val(item, 'nip') }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="6" sm="4" md="2" class="pa-0 ma-0">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{ $t('customers.regon') }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ val(item, 'regon') }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="6" sm="4" md="4" class="pa-0 ma-0">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{
              $t('customers.due-days')
            }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ val(item, 'due_days') }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="6" sm="4" md="4" class="pa-0 ma-0">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{
              $t('customers.default-varnish')
            }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ val(item, 'default_varnish') }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-for="(address, i) in item.addresses"
        :key="i"
        cols="6"
        sm="6"
        md="6"
        class="pa-0 ma-0"
      >
        <v-container class="mx-2 my-0">
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title
                >{{ $t('customers.address.address') }}:
                {{ address.name }}</v-list-item-title
              >
              <v-list-item-subtitle>
                {{ fullAddress(address) }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                {{ $t('customers.contact') }}: {{ val(address, 'contact') }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                {{ $t('customers.phone') }}: {{ val(address, 'phone') }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                {{ $t('customers.email') }}: {{ val(address, 'email') }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                {{ $t('customers.types') }}: {{ val(address, 'types') }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                {{ $t('customers.address.active') }}: {{ address.active }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  components: {},
  props: {
    item: {
      type: Object,
      required: true
    },
    headers: {
      type: Array,
      required: true
    }
  },
  computed: {},
  methods: {
    val(item, field) {
      let v = item[field];
      if (v) {
        return v;
      }
      return '----';
    },
    fullAddress(a) {
      return `${a.address}; ${a.zip} ${a.city}; ${a.country}`;
    }
  }
};
</script>

<style></style>
